<template>
  <el-container>
    <el-main>
      <div class="blodTitle">支付宝小程序</div>
      <img :style="{ marginBottom: isShouQuan ? '' : '20px' }" src="@/assets/image/alibanner.png" alt="" />
      <!-- 暂未授权支付小程序 -->
      <div class="isAuthorization" v-if="!isShouQuan">
        <div class="Authorizations" v-for="(item, index) in 2" :key="index">
          <div class="top">{{ index == 0 ? '未有支付宝小程序' : '已有支付宝小程序' }}</div>
          <div class="mid">
            {{ index == 0 ? '在支付宝公众平台注册小程序，完成注册后可以同步进行 信息完善和开发。' : '授权已有的支付宝小程序，完成支付配置设置，发布快速获得小程序' }}
          </div>
          <div class="bot" @click="regiOrGrant(index)">{{ index == 0 ? '快速注册' : '授权支付宝小程序' }}</div>
        </div>
      </div>
      <div class="blodTitle" v-if="isShouQuan">授权及发布小程序</div>
      <Empower v-if="isShouQuan && dataInfo" :dataInfo="dataInfo"></Empower>
      <div class="blodTitle">其他配置</div>
      <div class="otherConfig">
        <div class="configItem" v-for="(item, index) in otherConifg" :key="index">
          <img :src="item.img" alt="" />
          <div class="conTxt">
            <div class="top">{{ item.title }}</div>
            <div class="mid">{{ item.content }}</div>
            <div class="bot">
              <span @click="Jump(index)"> {{ index == 0 ? 'ISV授权后，自动配置' : '前往体验' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="blodTitle">流量运营</div>
      <div class="operateArr">
        <div v-for="(item, index) in operateArr" :key="index" :style="{ marginBottom: index <= 1 ? '20px' : '' }">
          <img :src="item.img" alt="" />
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="bot">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <div class="blodTitle">
        优秀案例
        <span class="subTitle">更多案例</span>
      </div>
      <div class="anliList">
        <div v-for="(item, index) in anliArr" :key="index">
          <img :src="item.logo" alt="" />
          <img class="qr" :src="item.qrcode" alt="" />
        </div>
      </div>
      <el-dialog title="体验小程序" :visible.sync="experienceFlag" width="52%">
        <Experience type="ali" :appstatus="dataInfo.appstatus" ref="Experience"></Experience>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import Empower from './sonPage/aliPaySon/ailiempower.vue'
import Experience from './sonPage/wxSon/experience.vue'
export default {
  components: {
    Empower,
    Experience
  },
  data() {
    return {
      isShouQuan: false,
      Characteristicfunction: [
        { title: '接入附近的小程序功能', content: '附近的小程序是线下商户在支付宝内重要的一个曝光渠道，通过附近的小程序，用户可以快速查看到门店信息以及享受到小程序服务' },
        { title: '小程序接入订阅消息', content: '消息推送，快捷发送商品，快捷提示。降低运营难度，确保消息送达率的同时，降低短信成本。随时对粉丝进行消息推送。增加粉丝黏性' },
        { title: '支付宝搜索功能', content: '开通支付宝搜索后，商家将获得丰富的展示能力。用户可以通过搜索品牌名称直达商家，同时，商家的服务和物品也有机会出现在相关搜索结果中。' },
        { title: '自定义装修小程序', content: '无需代码，快速制作，丰富的行业模板，任意更换，一键生成支付宝小程序。也可根据自己的风格装修专属自己的小程序，多种组件轻松上手。' }
      ],
      process: [
        { title: '在支付宝公众平台注册小程序账号', desc: '在支付宝公众平台注册小程序，完成注册后可以同步进行信息完善和开发。' },
        { title: '在彩蛋后台一键授权支付宝小程序', desc: '完成小程序开发者绑定、开发信息配置后，开发者可下载开发者工具、参考开发文档进行小程序的开发和调试。' },
        { title: '提交审核和发布', desc: '完成小程序开发后，提交代码至支付宝团队审核，审核通过后即可发布。' }
      ],
      otherConifg: [
        {
          img: require('@/assets/image/alidy.png'),
          title: '订阅消息',
          content: '营销类消息（签到提醒、活动提醒、抽奖提醒等）等，可通过订阅消息发送。订阅消息推送位置为：首页-服务提醒，消息TAB顶部-服务提醒。用户点击消息详情，跳转至小程序页面。'
        },
        {
          img: require('@/assets/image/ality.png'),
          title: '体验小程序',
          content: '上传小程序后可生成体验码，可在手机上预览小程序。小程序未上线前，可以添加体验者微信号，即可手机浏览小程序。'
        }
      ],
      operateArr: [
        {
          img: require('@/assets/image/pic01.png'),
          title: '中心化频道开放',
          content: '一起分享支付宝中心化频道触达用户机会，支付宝首页、会员频道、惠支付、花呗频道、信用频道、社区生活等更多的频道逐步开放。'
        },
        {
          img: require('@/assets/image/pic02.png'),
          title: '自运营入口开放',
          content:
            '在支付宝APP端开放链接用户的入口，更便捷的让合作伙伴结合自身运营诉求进行自运营，支付成功页、生活号、朋友TAB、小程序收藏、搜索栏、账单页、二维码扫一扫、卡包等多个支付宝端内入口和工具，助您打造拉新留存促活的运营闭环。'
        },
        { img: require('@/assets/image/pic03.png'), title: '多端开放', content: '一起分享阿里集团多端触达用户机会，手机淘宝、高德、UC浏览器等阿里集团更多亿级用户APP逐步开放。' },
        {
          img: require('@/assets/image/pic04.png'),
          title: '生态扶优政策',
          content:
            '为了帮助商家了解自身服务能力水平，满足用户对好服务的期待，我们通过服务竞争力指数（简称“SCI ”）来评价商户在支付宝客户端内经营服务的能力综合评价。SCI不同等级的商户，将有资格获得蚂蚁及阿里经济体内丰富的优质权益激励，等级越高享受的权益越多。'
        }
      ],
      anliArr: [
        { logo: require('@/assets/image/anli01.png'), qrcode: require('@/assets/image/qrcode.png') },
        { logo: require('@/assets/image/anli02.png'), qrcode: require('@/assets/image/qrcode.png') },
        { logo: require('@/assets/image/anli03.png'), qrcode: require('@/assets/image/qrcode.png') },
        { logo: require('@/assets/image/anli04.png'), qrcode: require('@/assets/image/qrcode.png') },
        { logo: require('@/assets/image/anli05.png'), qrcode: require('@/assets/image/qrcode.png') }
      ],
      dataInfo: {},
      experienceFlag: false
    }
  },
  created() {
    this.getStatus()
  },
  methods: {
    // 获取是否授权支付宝小程序
    getStatus(status) {
      let that = this
      this.$axios.post(this.$api.set.isAuth).then((res) => {
        if (res.code === 0) {
          if (res.result) {
            that.dataInfo = res.result
            that.isShouQuan = true
          } else {
            if (status) {
              that.$message.success('授权未成功')
            }
            that.isShouQuan = false
          }
        }
      })
    },
    // 注册or授权支付宝小程序
    regiOrGrant(index) {
      if (index == 0) {
        window.open('https://developers.alipay.com')
      } else {
        let that = this
        this.$axios
          .post(this.$api.set.authoAli, {
            from: 0
          })
          .then((res) => {
            if (res.code == 0) {
              that
                .$confirm('是否完成授权', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                .then(() => {
                  this.getStatus(1)
                })
                .catch(() => {})
              window.open(res.result, '_blank')
            }
          })
      }
    },
    // 体验 or 配置订阅
    Jump(index) {
      if (index == 0) {
        //
      } else {
        if (this.dataInfo.appstatus < 2 || !this.dataInfo.appstatus) {
          this.$message.warning('请先上传小程序')
          return
        }
        if (this.dataInfo.appstatus === 2 || this.dataInfo.appstatus === 3 || this.dataInfo.appstatus === 5) {
          this.experienceFlag = true
          this.$nextTick(() => {
            this.$refs.Experience.getQRcode()
          })
        } else {
          this.$message.warning('只有开发中或审核中版本可设置体验版')
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.blodTitle {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin: 40px 0 20px 0;
  .subTitle {
    color: #5654f3;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    margin-left: 23px;
  }
}
.el-container {
  width: 100%;
  background: #fff;
}
.el-main {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0 20px;
  & > img {
    width: 100%;
  }
  .isAuthorization {
    width: 100%;
    display: flex;
    & > div {
      width: 380px;
      padding: 20px;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #f7f8fa;
      margin-right: 20px;
    }
    .top {
      font-size: 18px;
      color: #333;
      text-align: center;
      font-weight: bold;
    }
    .mid {
      width: 100%;
      font-size: 14px;
    }
    .bot {
      width: 228px;
      height: 32px;
      background: #1676fe;
      border-radius: 2px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      align-self: center;
    }
  }
  .otherConfig {
    width: 100%;
    display: flex;
    .configItem {
      flex: 1;
      display: flex;
      align-items: center;
      border: 1px solid #ededed;
      border-radius: 2px;
      padding: 17px 20px;
      img {
        width: 50px;
        height: 50px;
      }
      .conTxt {
        margin-left: 20px;
        width: calc(100% - 65px);
        .top {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 4px;
        }
        .mid {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
        .bot {
          margin-top: 4px;
          span {
            color: #1676fe;
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
    }
    .configItem:last-child {
      margin-left: 15px;
    }
  }
  .operateArr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div {
      width: 49.5%;
      min-width: 783px;
      flex-shrink: 0;
      border: 1px solid #f2f2f2;
      box-shadow: 0px 0px 16px 0px rgba(105, 138, 201, 0.05);
      display: flex;
      padding: 20px;

      img {
        width: 352px;
        height: 222px;
        margin-right: 10px;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .bot {
        padding-right: 50px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
    }
  }
  .anliList {
    display: flex;
    align-items: center;
    & > div {
      position: relative;
      flex-shrink: 0;
    }
    .qr {
      width: 100px;
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: all 0.5s;
      transform-origin: center;
    }
    & > div:hover .qr {
      opacity: 1;
      transform: translateX(-50%) rotateY(360deg);
    }
  }
}
</style>
