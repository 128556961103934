<template>
  <div class="firstBox">
    <div class="itemBox">
      <div class="title">支付宝小程序logo：</div>
      <div class="content" style="color: #999"><img :src="dataInfo.logo" alt="" /></div>
    </div>
    <div class="itemBox">
      <div class="title">支付宝小程序名称：</div>
      <div class="content" style="color: #999">{{ dataInfo.name }}</div>
    </div>
    <div class="itemBox">
      <div class="title">appID：</div>
      <div class="content" style="color: #999">{{ dataInfo.auth_app_id }}</div>
    </div>
    <div class="itemBox">
      <div class="title">类别：</div>
      <div class="content" style="color: #999">{{ dataInfo.category_names }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.itemBox {
  display: flex;
  align-items: center;
  .title {
    width: 150px;
    text-align: right;
    white-space: nowrap;
    flex-shrink: 0;
    color: #818181;
  }
  .content {
    width: calc(100% - 160px);
    flex-shrink: 0;
    padding: 25px 0 23px 0;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
  }
}
</style>
